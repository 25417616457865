/* Library imports */
import React, { useState } from 'react'
import axios from 'axios'

/* Validations imports */
import { validateEmail } from '../validations'

/* CSS imports */
import '../styles/forgot-password.css'

/* Svg imports */
import logoSvg from '../images/logo.svg'


const ForgotPassword = ({ setOpenedPage }) => {

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const handleLoginButton = () => setOpenedPage('login')

    const isFormValid = () => {
        if (validateEmail(email).error)
            return false
        return true
    }

    const handleForgotPasswordFormSubmit = e => {

        e.preventDefault()

        if (!isFormValid())
            return

        const json = JSON.stringify({ email: email })
        axios.post(
            '/password-reset/initiate',
            json,
            { headers: { 'Content-Type': 'application/json' } })
            .then(({ data }) => {
                if (data.event === 'SUCCESS')
                    return setMessage('The link is sent to this email')
                else {
                    if (data.message === 'USER_NOT_FOUND')
                        return setMessage('This is email is not registered')
                }
            })
            .catch(e => setMessage('An error occured'))
    }

    return (
        <div className="forgot-password card">

            <div className="content">

                <div className="header">WELCOME BACK!</div>
                <div className="sub-header">ENTER YOUR EMAIL TO CONTINUE</div>
                
                <div className="logo">
                    <div className="logo__bar"></div>
                    <img className="logo__svg" src={logoSvg} alt="Logo" />
                </div>

                <form className="forgot-password__form form" onSubmit={handleForgotPasswordFormSubmit}>
                    
                    <input 
                        className={"input --email" + (validateEmail(email).error ? "" : " --valid")} 
                        type="email" name="email" 
                        placeholder="Enter email" 
                        value={email} 
                        onChange={e => setEmail(e.target.value)} />

                    <p className={"message" + (message ? " --valid" : "")}>{message}</p>
                    
                    <input type="submit" value="SEND LINK" className={"submit" + (isFormValid() ? "" : " --disabled")}/>
                </form>

                <div className="footer">
                    REMEMBERED YOUR PASSWORD?
                    <button className="footer__button" onClick={handleLoginButton}>GO BACK</button>
                </div>

            </div>

        </div>
    )
}

export default ForgotPassword