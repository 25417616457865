import Joi from 'joi'

export const validateEmail = (data) => {
    const schema = Joi.string().email({ tlds: {allow: false} }).required()
    return schema.validate(data)
}

export const validateFirstName = (data) => {
    const schema = Joi.string().alphanum().max(15).min(2).required()
    return schema.validate(data)
}

export const validateLastName = (data) => {
    const schema = Joi.string().alphanum().max(15)
    return schema.validate(data)
}

export const validatePassword = (data) => {
    const schema = Joi.string().min(6).required()
    return schema.validate(data)
}