// Library imports
import React, { useState } from "react";
import axios from "axios";

// CSS imports
import "../styles/login.css";

// Validations imports
import { validateEmail, validatePassword } from "../validations";

// Svg imports
import logoSvg from "../images/logo.svg";

const Login = ({ checkToken, setOpenedPage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const isFormValid = () => {
    if (validateEmail(email).error) return false;
    if (validatePassword(password).error) return false;
    return true;
  };

  const handleForgotPassword = () => setOpenedPage("forgot-password");

  const handleLoginFormSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid()) return;

    const json = JSON.stringify({ email, password });
    axios
      .post("/user/login", json, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => {
        if (data.event === "SUCCESS") {
          checkToken();
        } else if (data.event === "FAILURE") {
          if (data.message === "EMAIL_NOT_FOUND") {
            return setMessage("This is email is not registered");
          }
          if (data.message === "WRONG_PASSWORD") {
            return setMessage("This is the wrong password");
          }
        }
      })
      .catch((e) => setMessage("An error occured"));
  };

  const handleSignupButton = () =>
    (window.location.href = "https://www.emmox.com/#pricing");

  return (
    <div className="login card">
      <div className="content">
        <div className="header">WELCOME BACK!</div>
        <div className="sub-header">SIGN IN TO CONTINUE INTO EMMOX</div>

        <div className="logo">
          <div className="logo__bar"></div>
          <img className="logo__svg" src={logoSvg} alt="Logo" />
        </div>

        <form className="form login__form" onSubmit={handleLoginFormSubmit}>
          <input
            className={
              "input --email" + (validateEmail(email).error ? "" : " --valid")
            }
            type="email"
            name="email"
            placeholder="Enter email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            className={
              "input --password" +
              (validatePassword(password).error ? "" : " --valid")
            }
            type="password"
            name="password"
            placeholder="Enter password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <input
            type="submit"
            value="LOGIN"
            className={"submit" + (isFormValid() ? "" : " --disabled")}
          />
          <button
            className="secondary-button --forgot"
            onClick={handleForgotPassword}
          >
            FORGOT PASSWORD?
          </button>
          <p className={"message" + (message ? " --valid" : "")}>{message}</p>
        </form>
      </div>
      <div className="footer">
        DON'T HAVE AN ACCOUNT?
        <button className="footer__button" onClick={handleSignupButton}>
          SIGNUP NOW
        </button>
      </div>
    </div>
  );
};

export default Login;
