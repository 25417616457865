/* Library imports */
import React, { useState, useEffect } from "react";
import axios from "axios";

/* Components imports */
import Login from "./Login";
import Signup from "./Signup";
import Verify from "./Verify";
import ForgotPassword from "./ForgotPassword";

/* Styles imports */
import "../styles/globals.css";
import "../styles/landing.css";

const Landing = () => {
  const [openedPage, setOpenedPage] = useState("login");

  const checkToken = () => {
    axios
      .get("/app")
      .then(({ data }) => {
        if (data.event === "FAILURE") {
          const tokenInvalidMessages = [
            "AUTH_TOKEN_MISSING",
            "USER_NOT_FOUND",
            "AUTH_TOKEN_INVALID",
          ];
          if (tokenInvalidMessages.includes(data.message))
            return setOpenedPage("login");

          if (data.message === "EMAIL_NOT_VERIFIED")
            return setOpenedPage("verify");

          if (data.message === "PAYMENT_NOT_FOUND")
            return (window.location.href = "/pricing");
        } else {
          window.location.href = "/app";
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(checkToken, []);

  return (
    <div className="landing">
      <a className="home" href="/">
        <i className="fas fa-home"></i>
      </a>

      {openedPage === "login" && (
        <Login checkToken={checkToken} setOpenedPage={setOpenedPage} />
      )}
      {/* { (openedPage === 'signup') && <Signup checkToken={checkToken} setOpenedPage={setOpenedPage} />} */}
      {openedPage === "forgot-password" && (
        <ForgotPassword setOpenedPage={setOpenedPage} />
      )}
      {openedPage === "verify" && (
        <Verify checkToken={checkToken} setOpenedPage={setOpenedPage} />
      )}
    </div>
  );
};

export default Landing;
