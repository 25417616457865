import React from 'react';
import { render } from 'react-dom';
import axios from 'axios'

import Landing from './components/Landing'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

render(
  <Landing />,
  document.getElementById('root')
);
