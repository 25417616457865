/* Library imports */
import React, { useState, useEffect } from 'react'
import axios from 'axios'

/* CSS imports */
import '../styles/verify.css'

/* Svg imports */
import logoSvg from '../images/logo.svg'

const Verify = ({ checkToken, setOpenedPage }) => {

    const [message, setMessage] = useState('')

    const handleSendVerificationEmail = () => {
        setMessage('')
        axios.get('/verification/initiate')
        .then(({ data }) => {
            if (data.event === 'SUCCESS')
                return setMessage('The verification email has been sent')
            if (data.event === 'FAILURE') {
                return setOpenedPage('login')
            }
        })
        .catch(error => setMessage('An error occurred'))
    }

    const handleCheckVerification = () => {
        setMessage('')
        axios.get('/verification/check')
        .then(({ data }) => {
            if (data.event === 'SUCCESS')
                return checkToken()
            if (data.event === 'FAILURE') {
                if (data.message === 'EMAIL_NOT_VERIFIED')
                    return setMessage('Your email has not been verified yet')
                else {
                    return setOpenedPage('login')
                }
            }
        })
        .catch(error => setMessage('An error occurred'))
    }

    const handleLogout = () => setOpenedPage('login')

    useEffect(handleSendVerificationEmail, [setOpenedPage])
    useEffect(() => {
        const interval = 5000
        const intervalId = setInterval(handleCheckVerification, interval)
        return () => clearInterval(intervalId)
    }, [setOpenedPage])
    //useEffect(setInterval(handleCheckVerification, 5000), [])

    return (
        <div className="verify card">
            <div className="content">

                <div className="header">VERIFY !</div>
                <div className="sub-header">VERIFY YOUR EMAIL</div>
                
                <div className="logo">
                    <div className="logo__bar"></div>
                    <img className="logo__svg" src={logoSvg} alt="Logo" />
                </div>
                <div className="form verify__form">
                    <p className={"message" + (message ? " --valid" : "")}>{message}</p>

                    <button 
                        className="primary-button --resend"
                        onClick={handleSendVerificationEmail}>RESEND VERIFICATION EMAIL</button>
                </div>
            </div>

            <div className="footer">
                NOT YOUR ACCOUNT? 
                <button className="footer__button" onClick={handleLogout}>LOG OUT</button>
            </div>
        </div>
    )
}

export default Verify